<template>
    <div class="container
            max-w-screen-md mx-auto prose p-4 pt-24">
        <h2 class="text-3xl">Get In Touch</h2>

        <div class="section-title">
            <img src="/email.svg">
            <h3>Email</h3>
        </div>
        <ul>
            <li>
                <a href="mailto:admin@gacorvera.com">admin@gacorvera.com</a>
            </li>
        </ul>

        <hr class="divider" />

        <div class="section-title">
            <img src="/facebook.svg">
            <h3>Facebook</h3>
        </div>
        <ul>
            <li>
                <a href="https://www.facebook.com/gacorveraconstructioncorporation">GACorvera Construction Corporation</a>
            </li>
        </ul>

        <hr class="divider" />

        <div class="section-title">
            <img src="/phone.svg">
            <h3>Landline</h3>
        </div>
        <ul>
            <li>
                Admin: <a href="tel:+63284412641">(+632) 8441-2641</a>
            </li>
            <li>
                Operations: <a href="tel:+63282946386">(+632) 8294-6386</a>
            </li>
        </ul>

        <hr class="divider" />

        <div class="section-title">
            <img src="/map.svg">
            <h3>Address</h3>
        </div>
        <ul>
            <li>
                <a href="https://www.google.com.ph/maps/place/GA+Corvera+Construction+Corporation/@14.6452255,121.0500667,17z/data=!3m1!4b1!4m2!3m1!1s0x3397b70bbf3bd7bb:0xc33e08fc147f5955?hl=en">Rm 201-202 Capitol Masonic Building II, #37 Matalino St., Central Diliman, Quezon City</a>
            </li>
        </ul>
        <p>
            <iframe
                class="max-w-full"
                allowfullscreen="allowfullscreen"
                height="600"
                frameborder="0"
                style="border: 0;"
                width="800"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.188824456421!2d121.0500667148407!3d14.645220289774063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b70bbf3bd7bb%3A0xc33e08fc147f5955!2sGA+Corvera+Construction+Corporation!5e0!3m2!1sen!2sph!4v1461645883723">
            </iframe>
        </p>
    </div>
</template>

<script>
// Source: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
import { useRoute } from 'vue-router'
import meta from './../AppMeta.js'

export default {
    data: () => ({
        route: useRoute(),
        meta: {
            title: 'G.A. Corvera Construction Corporation | Contact',
            tags: [
                {
                    property: 'og:title',
                    content: 'G.A. Corvera Construction Corporation | Contact'
                }, {
                    property: 'og:url',
                    content: 'https://gacorvera.com/contact'
                }, {
                    name: 'description',
                    content: 'Contact details of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:description',
                    content: 'Contact details of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:image',
                    content: 'https://one.sgp1.cdn.digitaloceanspaces.com/gacorvera/gac-logo-720.png'
                }
            ]
        }
    }),
    mounted() {
        if (this.route.path === '/contact') {
            meta.update(this.meta)
        }
    }
}
</script>

<style scoped>
.section-title {
    @apply flex items-center;
}

.section-title h3 {
    @apply m-0;
}

.section-title img {
    @apply block h-8 m-0 mr-2;
}

.divider {
    @apply my-8;
}
</style>
